import * as React from "react"
import Layout from "../components/layout"

import Button from "../modules/button";

const CareersPage = () => {
    return (
        <Layout className={'careers'} title={'Careers'}>
            <section className={'careers-section'}>
                <div className={'container'}>

                    <h1 className={'text-center thin color'}>Come work with us</h1>

                    <div className={'text'}>
                        Penumbra brings privacy to proof-of-stake, allowing users to transact, stake, swap, and marketmake without disclosing their personal information, account activity, or trading strategies to the entire world – while still permitting selective disclosure to appropriate parties. Penumbra Labs is incubating the development of this decentralized, public network, and working with us is a unique opportunity to solve exciting problems and build new financial infrastructure in a collaborative, public work environment, and to have a meaningful stake in the creation of a new protocol.
                    </div>

                    <Button url={'https://boards.greenhouse.io/penumbralabs'} class={'centered'} title={'Jobs Board'}/>

                </div>
            </section>

        </Layout>
    )
}

export default CareersPage
